import React, { useEffect } from 'react';
import './BBall.scss';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import bballStore from './reducers/bballStore';
import Splits from './Splits';
import Champion from './Champion';
import FirstFour from './FirstFour';
import Nav from './Nav/Nav';

let store = createStore(bballStore);
let location: any;

const unsubscribe = store.subscribe(() => {
  if (location) {
    location(`?L=${store.getState().gameArray.join('')}`);
  }
});
const BBall = (props: any) => {
  
  const params = new URLSearchParams(window.location.search);
  const originalSaveString: string = params.get('L') || localStorage['pp-mm-2023'] || '';
  useEffect(() => {
    fetch('https://apim.playoffpredictors.com/leagueengine/tournaments/7/A33E208A-BB4A-43C8-98E3-17CF0A2F20EE')
      .then((res) => res.json())
      .then((result) => {
        let { bracket, firstFour, name, stub, latest } = result;
        var initializeWith = { bracket, firstFour, name, stub, latest: originalSaveString || latest };

        store.dispatch({ type: 'LOAD', initializeWith });
      });

  }, [])


  useEffect(() => {
    let state = store.getState();
    if (state.loaded) {
      const saveString: string = originalSaveString || localStorage[state.stub] || state.latest || '';
      let gameArray = saveString.split('');
      store.dispatch({ type: 'SET_INITIAL_URL', gameArray });
    }
  }, [store.getState().loaded])

  location = loc => {
    props.history.push(loc);
    localStorage[store.getState().stub] = loc.split('=')[1];
  };

  if (store.getState().loaded) {
    return (
      <>
        <Provider store={store}>
          <div className="flex">
            <Nav />
          </div>
          {
            <div id="bracket">
              <div className="container">

                <Splits split="one" />
                <Champion games={[store.getState().east, store.getState().west, store.getState().south, store.getState().midwest]} />
                <Splits split="two" />
              </div>
              <FirstFour games={store.getState().firstFour} />
            </div>
          }
        </Provider>
      </>
    )
  } else { return <div>loading</div> };

};

export default BBall;
