import React from 'react';
import Game from '../../../templates/Game/Game';
import { Matchup } from '../../../types/Matchup';
import './BBall.scss';

const FirstFour = (props: any) => {
  const { games, split } = props;
  const mw = games.filter(p => p.confName == 'midwest');
  const s = games.filter(p => p.confName == 'south');
  const west = games.filter(p => p.confName == 'west');
  const east = games.filter(p => p.confName == 'east');
  return (
    <>
    <h2 className="round-details">First Four</h2>
    <div id="first-four">
      <div className="first-four-match">
        <h3 className="round-details">MW</h3>
        {mw
          .filter(p => p.top.seed == 11)
          .map(g => (
            <Game key={g.key} matchup={g} />
          ))}
      </div>
      <div className="first-four-match">
        <h3 className="round-details">East</h3>
        {east
          .filter(p => p.top.seed == 16)
          .map(g => (
            <Game key={g.key} matchup={g} />
          ))}
      </div>
      <div className="first-four-match">
        <h3 className="round-details">South</h3>
        {s
          .filter(p => p.top.seed == 16)
          .map(g => (
            <Game key={g.key} matchup={g} />
          ))}
      </div>
      <div className="first-four-match">
        <h3 className="round-details">West</h3>
        {west
          .filter(p => p.top.seed == 11)
          .map(g => (
            <Game key={g.key} matchup={g} />
          ))}
      </div>
    </div>
    </>
  );
};

export default FirstFour;
