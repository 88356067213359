import React from 'react';

import { TeamModel } from '../../types/TeamModel';
import { Button } from '@material-ui/core';
import { ReactReduxContext } from 'react-redux';
import styles from './Team.module.scss';

const Team = (props: any) => {
  const team: TeamModel = props.team;
  const winner: TeamModel = props.winner;

  return (
    <ReactReduxContext.Consumer>
      {({ store }) => {
        let selectedTeam = 'none';
        if (winner) {
          if (winner == team) {
            selectedTeam = styles.winner;
          } else {
            selectedTeam = styles.loser;
          }
        }
        return (
          <>
            <li className={`team team-${props.location}`}  onClick={() => {
                  store.dispatch({ type: 'GAME_PICKED', teamKey: props.updateKey, pick: props.setVal });
                }}>
              <sup className={selectedTeam}>{team.seed}</sup>&nbsp;
              <Button
               
                className={selectedTeam}
              >
                <img
                  src={`https://static.playoffpredictors.com/div-1/${team.conference}/${team.division}/${team.teamAbbreviation}.svg`}
                  width="25"
                  height="25"
                />
                <span className="team-name">{team.name}</span>
              </Button>
            </li>
          </>
        );
      }}
    </ReactReduxContext.Consumer>
  );
};

export default Team;
