import { Utils } from './../utilities/utils';
const getDefaultState = ():any => {
  return {
    games: {},
    latest: '00000000000000000000000000000000000000000000000000000000000000000000',
    firstFour: [],
    east: {},
    west: {},
    south: {},
    midwest: {},
    gameAssociation:{},
    tournamentStructure: {
      rounds: 4,
      divisions: 4
    },
    gameArray: Array(68).fill(0),
    associatedGames: [],
    reverseAssociation:[],
    name: '',
    stub: '',
    loaded: false,
    initialized: false
  };
};

const bballStore = (state = getDefaultState(), action: any) => {

  const getTopBottom = games => {
    try {
      let top = state.gameAssociation[games[0]];
      let bottom = state.gameAssociation[games[1]];
      if (top.winner && bottom.winner) {
        return [top.winner, bottom.winner];
      }
      if (top.winner) {
        return [top.winner, null];
      }
      if (bottom.winner) {
        return [null, bottom.winner];
      }
    } catch (err) {
      console.warn(err);
    }
    return [null, null];
  };

  switch (action.type) {
    case 'LOAD':{
      var {bracket, firstFour, name, stub, latest} = action.initializeWith;
      state.gameArray = latest.split('');
      state.latest = latest;
      const games = Utils.getMatches(bracket, firstFour);
      const gameAssociation = new Array(70);
      Object.keys(games).map(k => {
        for (let game of games[k]) {
          gameAssociation[game.key] = game;
        }
      });
      let associatedGames = [
        { key: '0', val: 8 }, // east
        { key: '7', val: 8 },
        { key: '4', val: 9 },
        { key: '3', val: 9 },
        { key: '5', val: 10 },
        { key: '2', val: 10 },
        { key: '6', val: 11 },
        { key: '1', val: 11 },
        { key: '8', val: 12 },
        { key: '9', val: 12 },
        { key: '10', val: 13 },
        { key: '11', val: 13 },
        { key: '12', val: 14 },
        { key: '13', val: 14 },
        { key: '14', val: 47 },
        { key: '16', val: 24 }, //south
        { key: '23', val: 24 },
        { key: '20', val: 25 },
        { key: '19', val: 25 },
        { key: '21', val: 26 },
        { key: '18', val: 26 },
        { key: '22', val: 27 },
        { key: '17', val: 27 },
        { key: '24', val: 28 },
        { key: '25', val: 28 },
        { key: '26', val: 29 },
        { key: '27', val: 29 },
        { key: '28', val: 30 },
        { key: '29', val: 30 },
        { key: '30', val: 31 },
        { key: '32', val: 40 }, // west
        { key: '39', val: 40 },
        { key: '36', val: 41 },
        { key: '35', val: 41 },
        { key: '37', val: 42 },
        { key: '34', val: 42 },
        { key: '38', val: 43 },
        { key: '33', val: 43 },
        { key: '40', val: 44 },
        { key: '41', val: 44 },
        { key: '42', val: 45 },
        { key: '43', val: 45 },
        { key: '44', val: 46 },
        { key: '45', val: 46 },
        { key: '46', val: 47 },
        { key: '48', val: 56 }, // midwest
        { key: '55', val: 56 },
        { key: '52', val: 57 },
        { key: '51', val: 57 },
        { key: '53', val: 58 },
        { key: '50', val: 58 },
        { key: '54', val: 59 },
        { key: '49', val: 59 },
        { key: '56', val: 60 },
        { key: '57', val: 60 },
        { key: '58', val: 61 },
        { key: '59', val: 61 },
        { key: '60', val: 62 },
        { key: '61', val: 62 },
        { key: '62', val: 31 },
        { key: '31', val: 63 },
        { key: '47', val: 63 },
        { key: '63', val: 63 },

        
        { key: '64', val: 37 }, // First 4
        { key: '65', val: 0 }, // FDU
        { key: '66', val: 53 }, //MW Pitt
        { key: '67', val: 16} // 
      ];
      let reverseAssociation: any = {};
      associatedGames.map(aG => {
        if (+aG.key == 63) {
          return;
        }
        let reverse = reverseAssociation[aG.val];
        if (!reverse) {
          reverseAssociation[aG.val] = [aG.key];
        } else {
          reverseAssociation[aG.val].push(aG.key);
        }
      });
      state.gameAssociation = gameAssociation;
      state.loaded = true;
      state.associatedGames = associatedGames;
      state.reverseAssociation = reverseAssociation;
      state.games = games;
      state.east = games["east"];
      state.west = games["west"];
      state.midwest = games["midwest"];
      state.south = games["south"];
      state.firstFour = games["firstFour"];
      state.name = name;
      state.stub = stub;
      return {...state}
    }
    
    case 'SET_INITIAL_URL': {
      const gameArray: string[] = action.gameArray.concat([]);
      for (let j = 63; j < gameArray.length; j++) {
        let pick = parseInt(gameArray[j], 10);
        if (pick === 0) {
          continue;
        }
        const g = state.associatedGames.find(k => +k.key == j);
        if (!g) {
          return { ...state };
        }
        const gameBeingPicked = state.gameAssociation[j];
        const associatedGame = state.gameAssociation[g.val];

        if (pick == 1) {
          //top
          gameBeingPicked.winner = gameBeingPicked.top;
        } else if (pick == 2) {
          //bottom
          gameBeingPicked.winner = gameBeingPicked.bottom;
        }

        associatedGame.bottom = gameBeingPicked.winner;
      }

      for (let i = 0; i < gameArray.concat([]).slice(0, 63).length; i++) {
        let pick = parseInt(gameArray[i], 10);
        if (pick == 0) continue;
        let gameBeingPicked = state.gameAssociation[i];
        try {
          if (gameBeingPicked) {
            if (pick == 1) {
              //top
              gameBeingPicked.winner = gameBeingPicked.top;
            } else if (pick == 2) {
              gameBeingPicked.winner = gameBeingPicked.bottom;
            } else {
              gameBeingPicked.winner = null;
            }
          }
        } catch (ex) {
          console.warn(ex);
          //swallow
        }
      }

      for (let j = 0; j < gameArray.length; j++) {
        let pick = parseInt(gameArray[j], 10);
        if (pick === 0) {
          continue;
        }
        const gameBeingPicked = state.gameAssociation[j];
        try {
          const g = state.associatedGames.find(k => +k.key == j);
          if (!g) {
            return { ...state };
          }
          const associatedGame = state.gameAssociation[g.val];

          if (pick == 1) {
            //top
            gameBeingPicked.winner = gameBeingPicked.top;
          } else if (pick == 2) {
            //bottom
            gameBeingPicked.winner = gameBeingPicked.bottom;
          }
          if (gameBeingPicked.key < 64) {
            const reverseAssociation = state.reverseAssociation[g.val];
            if (reverseAssociation) {
              const winners = getTopBottom(reverseAssociation);

              associatedGame.top = winners[0];
              associatedGame.bottom = winners[1];
            } else {
              console.warn(`no reverse association for gameArray # ${j}`);
            }
          } else {
            associatedGame.bottom = gameBeingPicked.winner;
          }
        } catch (ex) {
          debugger;
        }
      }
      
      for (let j = 0; j < gameArray.length; j++) {
        let pick = parseInt(gameArray[j], 10);
        if (pick === 0) {
          continue;
        }
        const gameBeingPicked = state.gameAssociation[j];
        try {
          const g = state.associatedGames.find(k => +k.key == j);
          if (!g) {
            return { ...state };
          }
          const associatedGame = state.gameAssociation[g.val];

          if (pick == 1) {
            //top
            gameBeingPicked.winner = gameBeingPicked.top;
          } else if (pick == 2) {
            //bottom
            gameBeingPicked.winner = gameBeingPicked.bottom;
          }
          if (gameBeingPicked.key < 64) {
            const reverseAssociation = state.reverseAssociation[g.val];
            if (reverseAssociation) {
              const winners = getTopBottom(reverseAssociation);

              associatedGame.top = winners[0];
              associatedGame.bottom = winners[1];
            } else {
              console.warn(`no reverse association for gameArray # ${j}`);
            }
          } else {
            associatedGame.bottom = gameBeingPicked.winner;
          }
        } catch (ex) {
          debugger;
        }
      }

      return { ...state, gameArray, initialized: true };
    }
    case 'GAME_PICKED':
      const g = state.associatedGames.find(k => k.key == action.teamKey);
      if (!g) {
        return { ...state };
      }
      const reverseAssociation = state.reverseAssociation[g.val];
      const associatedGame = state.gameAssociation[g.val];
      const gameArray: number[] = state.gameArray.concat([]);
      gameArray[action.teamKey] = action.pick;
      const gameBeingPicked = state.gameAssociation[action.teamKey];

      let loser;
      let teamThatWasPicked;
      if (action.pick == 1) {
        //top
        if (gameBeingPicked.winner == gameBeingPicked.top) {
          gameBeingPicked.winner = null;
          gameArray[action.teamKey] = 0;
        } else {
          gameBeingPicked.winner = gameBeingPicked.top;
          loser = gameBeingPicked.bottom;
        }
        teamThatWasPicked = gameBeingPicked.top;
      } else if (action.pick == 2) {
        //bottom
        if (gameBeingPicked.winner == gameBeingPicked.bottom) {
          gameBeingPicked.winner = null;
          gameArray[action.teamKey] = 0;
        } else {
          gameBeingPicked.winner = gameBeingPicked.bottom;
          loser = gameBeingPicked.top;
        }
        teamThatWasPicked = gameBeingPicked.bottom;
      }
      if (gameBeingPicked.key < 64) {
        const winners = getTopBottom(reverseAssociation);
        if (gameBeingPicked.key != 63 && winners.length == 2 && associatedGame) {
          associatedGame.top = winners[0];
          associatedGame.bottom = winners[1];
        }
      } else {
        associatedGame.bottom = gameBeingPicked.winner;
        associatedGame.winner = null;
        
        if(associatedGame.key == 63){
          gameArray[associatedGame.key] = 0;
        }

      }
      // nuke the entire state stack upwind of this game
      let newKey = associatedGame.key;
      try {
        if (associatedGame.winner) {
          if (
            associatedGame.winner.name != associatedGame.top.name &&
            associatedGame.winner.name != associatedGame.bottom.name
          ) {
            associatedGame.winner = null;
          }
        }
      } catch (ex) {
        associatedGame.winner = null;
      }

      while (true && newKey != 63) {
        let g = state.associatedGames.find(k => k.key == newKey);
        gameArray[newKey] = 0;
        if (g) {
          newKey = g.val;
          if (!state.gameAssociation[newKey].top && !state.gameAssociation[newKey].bottom) {
            break;
          }
          if (loser) {
            if (state.gameAssociation[newKey].top == loser) {
              state.gameAssociation[newKey].top = null;
            }
            if (state.gameAssociation[newKey].bottom == loser) {
              state.gameAssociation[newKey].bottom = null;
            }
          } else {
            if (state.gameAssociation[newKey].top == teamThatWasPicked) {
              state.gameAssociation[newKey].top = null;
            }
            if (state.gameAssociation[newKey].bottom == teamThatWasPicked) {
              state.gameAssociation[newKey].bottom = null;
            }
          }

          state.gameAssociation[newKey].winner = null;
        } else {
          break;
        }
      }
      return { ...state, gameArray };
    case 'RESET': {
      if (confirm('Are you sure you want to reset your picks?')) {
        Object.keys(state.games).map(confName => {
          state.games[confName].map(game => {
            state.gameAssociation[game.key].winner = null;
            if (!state.gameAssociation[game.key].default) {
              state.gameAssociation[game.key].top = null;
              state.gameAssociation[game.key].bottom = null;
            }
          });
        });

        const gameArray = Array(68).fill(0);
        return { ...state, gameArray };
      }
      return { ...state };
    }
    case 'LATEST': {
      window.location.replace(`?L=${action.latest}`);
      return { ...state }
    }

  }

  return state;
};
export default bballStore;
