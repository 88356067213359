import React from 'react';
import Game from '../../../templates/Game/Game';
import { Matchup } from '../../../types/Matchup';
import { connect } from 'react-redux';
import './BBall.scss';
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component';

const mapState = (state, ownProps) => {
  const { east, west, south, midwest } = state;
  let games: any[] = [];
  if (ownProps.split == 'one') {
    games = [east, west];
  } else {
    games = [south, midwest];
  }
  return {
    games,
    ...ownProps
  };
};

const useGameLogic = () => {
  const getGameForHomeSeed = (games: Matchup[], seed: number) => games.find(p => p && p.top && p.top.seed == seed);
  const ncaaOrdering = (games: Matchup[]) => {
    return [
      getGameForHomeSeed(games, 1),
      getGameForHomeSeed(games, 8),
      getGameForHomeSeed(games, 5),
      getGameForHomeSeed(games, 4),
      getGameForHomeSeed(games, 6),
      getGameForHomeSeed(games, 3),
      getGameForHomeSeed(games, 7),
      getGameForHomeSeed(games, 2),
      ...games.filter(p => !p.default)
    ];
  };

  return {
    getGameForHomeSeed,
    ncaaOrdering
  };
};

const Splits = (props: any) => {
  const { ncaaOrdering } = useGameLogic();
  const { games, split } = props;
  return (
    <>
      <div className={`split split-${split}`}>
        {split == 'one' && (
          <>
            <div className="round round-one">
              <div className="round-details">First Round</div>

              {ncaaOrdering(games[0].filter(p => p.round == 1)).map(g => g && <Game key={g.key} matchup={g} />)}
              <hr />
              <FreestarAdSlot
          publisher='playoffpredictors-com'
          placementName='playoffpredictors_mm_incontent_1'
        />
              {ncaaOrdering(games[1].filter(p => p.round == 1)).map(g => g && <Game key={g.key} matchup={g} />)}
              <FreestarAdSlot
          publisher='playoffpredictors-com'
          placementName='playoffpredictors_mm_incontent_2'
        />
            </div>
            <div className="round round-two">
              <div className="round-details">
                Second
                <br />
                Round
              </div>

              {games[0].filter(p => p.round == 2).map(g => g && <Game key={g.key} matchup={g} />)}
              <hr />
              {games[1].filter(p => p.round == 2).map(g => g && <Game key={g.key} matchup={g} />)}
            </div>
            <div className="round round-three">
              <div className="round-details">Sweet 16</div>

              {games[0].filter(p => p.round == 3).map(g => g && <Game key={g.key} matchup={g} />)}
              <hr />
              {games[1].filter(p => p.round == 3).map(g => g && <Game key={g.key} matchup={g} />)}
            </div>
            <div className="round round-four">
              <div className="round-details">Elite Eight</div>

              {games[0].filter(p => p.round == 4).map(g => g && <Game key={g.key} matchup={g} />)}
              <hr />
              {games[1].filter(p => p.round == 4).map(g => g && <Game key={g.key} matchup={g} />)}
            </div>
          </>
        )}
        {split == 'two' && (
          <>
            <div className="round round-four">
              <div className="round-details">Elite Eight</div>

              {games[0].filter(p => p.round == 4).map(g => g && <Game key={g.key} matchup={g} />)}
              <hr />
              {games[1].filter(p => p.round == 4).map(g => g && <Game key={g.key} matchup={g} />)}
            </div>
            <div className="round round-three">
              <div className="round-details">Sweet 16</div>

              {games[0].filter(p => p.round == 3).map(g => g && <Game key={g.key} matchup={g} />)}
              <hr />
              {games[1].filter(p => p.round == 3).map(g => g && <Game key={g.key} matchup={g} />)}
            </div>
            <div className="round round-two">
              <div className="round-details">
                Second
                <br />
                Round
              </div>

              {games[0].filter(p => p.round == 2).map(g => g && <Game key={g.key} matchup={g} />)}
              <hr />
              {games[1].filter(p => p.round == 2).map(g => g && <Game key={g.key} matchup={g} />)}
            </div>
            <div className="round round-one">
              <div className="round-details">First Round</div>

              {ncaaOrdering(games[0].filter(p => p.round == 1)).map(g => g && <Game key={g.key} matchup={g} />)}
              <hr />
              <FreestarAdSlot
          publisher='playoffpredictors-com'
          placementName='playoffpredictors_mm_incontent_3'
        />
              {ncaaOrdering(games[1].filter(p => p.round == 1)).map(g => g && <Game key={g.key} matchup={g} />)}
              <FreestarAdSlot
          publisher='playoffpredictors-com'
          placementName='playoffpredictors_mm_incontent_4'
        />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default connect(mapState)(Splits);
