import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, match } from 'react-router-dom';
import { Identifiable } from './types/identifiable';
import BBall from './components/ncaa/bball/BBall';
import './App.css';
import Nav from './components/ncaa/bball/Nav/Nav';

class App extends Component {
  constructor(public props: any) {
    super(props);
  }
  render() {
    return (
      <>
        <Router>
          <div>
            <Route path="/" location={this.props.location} component={BBall} />
          </div>
        </Router>
      </>
    );
  }
}

export default App;
