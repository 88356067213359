import React, { useState } from 'react';
import './Game.module.scss';
import Team from '../Team/Team';

const Game = (props: any) => {
  const { top, bottom, key } = props.matchup;
  let winner = props.matchup.winner;
  try {
    if (winner && top && bottom) {
      if (winner.name != top.name && winner.name != bottom.name) {
        winner = null;
      }
    } else {
      winner = null;
    }
  } catch (ex) {
    
  }

  return (
    <ul className={`matchup ${props.gameType || ''}`}>
      {top && <Team updateKey={key} team={top} location="top" setVal={1} iterator={key} winner={winner} />}
      {!top && (
        <li className="team team-top">
          <span>TBD</span>
        </li>
      )}
      {bottom && <Team updateKey={key} team={bottom} location="bottom" setVal={2} iterator={key} winner={winner} />}
      {!bottom && (
        <li className="team team-bottom">
          <span>TBD</span>
        </li>
      )}
    </ul>
  );
};

export default Game;
