import { GameModel } from "./../../../../types/GameModel";
import { TournamentStructure } from "../../../../types/TournamentStructure";

export class Utils {
  static getMatches(
    teams: TournamentStructure,
    firstFour
  ): { [confName: string]: GameModel[] } {
    const games = {};
    let iterator = 0;
    Object.keys(teams).map((key) => {
      games[key] = [];
      let highest = 16;

      for (var lowest = 1; lowest <= 8; lowest++) {
        const game = {
          key: iterator++,
          confName: key,
          top: teams[key].find((p) => p.seed == lowest),
          bottom: teams[key].find((p) => {
            return p.seed == highest;
          }),
          default: true,
          round: 1,
        };
        if (game.bottom && game.bottom.name == "TBD") {
          game.bottom = undefined;
        }
        games[key].push(game);
        highest--;
      }
      let extraGames: any[] = [];
      for (let i = 0; i < 8; i++) {
        // Prevents creating 2 inter-conference final games
        if (iterator == 15) {
          iterator++;
          continue;
        }
        const game = {
          top: null,
          bottom: null,
          key: iterator,
          default: false,
          round: i < 4 ? 2 : i < 6 ? 3 : 4,
        };
        if (iterator == 31 || iterator == 47) {
          game.round = 5;
        }
        if (iterator == 63) {
          game.round = 6;
        }
        extraGames.push(game);

        iterator++;
      }

      games[key] = games[key].concat(extraGames);
      highest = 16;
    });
    games["firstFour"] = [];
    Object.keys(firstFour).map((region) => {
      for (const game of firstFour[region]) {
        const gameExpanded = {
          key: iterator++,
          confName: region,
          default: true,
          round: 1,
          ...game,
        };
        games["firstFour"].push(gameExpanded);
      }
    });
    return games;
  }
}
