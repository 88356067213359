import React from 'react';
import styles from './Nav.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import { ReactReduxContext } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, Button } from '@material-ui/core';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createStore } from 'redux';
import bballStore from '../reducers/bballStore';

let store = createStore(bballStore);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  title: {
    font: '500 20px/32px Roboto',
    fontFamily: "'Bowlby One',cursive"
  },
  secondary: {
    fontFamily: "'Bowlby One',cursive",
    marginRight: '50px'
  },
  '& > *': {
    margin: theme.spacing(1),
  }
}));

const muiTheme = createMuiTheme({
  palette: {
    primary: { main: '#0d47a1' },
    secondary: {
      main: '#FFF'
    }
  }
});

const theme = {
  ...muiTheme
};

const Nav = (props: any) => {
  const classes = useStyles();
  const getLatest = () => {
    fetch('https://apim.playoffpredictors.com/leagueengine/tournaments/7/A33E208A-BB4A-43C8-98E3-17CF0A2F20EE')
      .then((res) => res.json())
      .then((result) => {
        let { latest } = result;
        store.dispatch({ type: 'LATEST', latest });
      });
  }
  return (
    <ReactReduxContext.Consumer>
      {({ store }) => {
        return (
          <div className={classes.root}>
            <ThemeProvider theme={theme}>
              <AppBar position="fixed">
                <Toolbar>
                  <Typography variant="h1" className={classes.title}>
                    <span className={styles.title}>
                      <a href="/">
                        <img
                          src="https://static.playoffpredictors.com/site/pp-color.svg"
                          width="48"
                          className={styles.ppImg}
                        />
                        <span className={styles.ppTopText}>Playoff</span>
                        <span className={styles.ppBottomText}>Predictors</span>
                      </a>
                    </span>
                  </Typography>
                  <Typography variant="h2" className={classes.secondary}>
                    March Madness 2023
                  </Typography>
                  <Button variant="contained" color="primary" onClick={getLatest}>Update</Button>&nbsp;
                  <Button variant="contained" color="secondary" onClick={() => store.dispatch({type: 'RESET' })}>Reset</Button>
                </Toolbar>
              </AppBar>
            </ThemeProvider>
          </div>
        );
      }}
    </ReactReduxContext.Consumer>
  );
};

export default Nav;
